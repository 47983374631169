<template>
  <div v-if="show" class="mb-4">
    <h3 class="my-3  member-list-title">{{ title }}</h3>
    <member-item v-for="member in members" :key="member.id" :member="member" :main="main" :control="control"
      :providerId="providerId" :storeId="storeId" :deleteItemCallback="deleteItemCallback" :refresh="refresh"
      :role="role" :memberRoles="memberRoles" :memberRole="memberRole" />
  </div>
</template>

<script>
export default {
  props: {
    role: {
      type: String,
      default: 'provider',
    },
    memberRole: String,
    main: {
      type: Boolean,
      default: false,
    },
    control: {
      type: Boolean,
      default: false,
    },
    members: Array,
    providerId: String,
    storeId: String,
    deleteItemCallback: Function,
    refresh: Function,
    memberRoles: Array,
  },
  data() {
    return {

    }
  },
  components: {
    memberItem: () => import("@/modules/member/components/liffMemberItem.vue"),
  },
  computed: {
    show() {
      return this.members.length > 0
    },
    title() {
      return this.$t(this.memberRole)
    },
  },
};
</script>
<style lang="sass">
.provider-store-layout
  .member-list-title
    color: #122a47
    font-size: 14px 
.linerp-layout
  .member-list-title
    color: #401300 !important
    font-size: 14px 
</style>